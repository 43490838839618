// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gpt3__features {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  
}

.gpt3__features-heading {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  margin-right: 5rem;
}

.gpt3__features-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
}
.gpt3__features-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #ff8a71;
  margin-top: 2rem;
}

.gpt3__features-container {
  flex: 1.5 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}



@media screen and (max-width: 990px) {
  .gpt3__features {
    flex-direction: column;
  }
  .gpt3__features-heading {
    margin: 0 0 2rem 0;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__features-heading h1{
    font-size: 28px;
    line-height: 38px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/features/features.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;;AAErB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAS;EACT,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;AACxB;;;;AAIA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".gpt3__features {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  \n}\n\n.gpt3__features-heading {\n  flex: 1;\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-direction: column;\n  text-align: left;\n  margin-right: 5rem;\n}\n\n.gpt3__features-heading h1 {\n  font-family: var(--font-family);\n  font-weight: 800;\n  font-size: 34px;\n  line-height: 45px;\n}\n.gpt3__features-heading p {\n  font-family: var(--font-family);\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 30px;\n  color: #ff8a71;\n  margin-top: 2rem;\n}\n\n.gpt3__features-container {\n  flex: 1.5;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n}\n\n\n\n@media screen and (max-width: 990px) {\n  .gpt3__features {\n    flex-direction: column;\n  }\n  .gpt3__features-heading {\n    margin: 0 0 2rem 0;\n  }\n}\n\n@media screen and (max-width: 550px) {\n  .gpt3__features-heading h1{\n    font-size: 28px;\n    line-height: 38px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
