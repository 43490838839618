// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gpt3__blog-container_article {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  background: var(--color-footer);
}
.gpt3__blog-container_article-image {
  width: 100%;
  height: 100%;
  background: var(--color-footer);
}
.gpt3__blog-container_article-image img{
  width: 100%;
  height: 100%;
  
}
.gpt3__blog-container_article-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 100%;
}
.gpt3__blog-container_article-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  color: #fff ;
  margin-bottom: 5rem;
  cursor: pointer;
}
.gpt3__blog-container_article-content p {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 11px;
  line-height: 35px;
  color: #fff ;
}

.gpt3__blog-container_article-content p:last-child {
  cursor: pointer;
}



@media screen and (max-width: 550px) {
  .gpt3__blog-container_article-content h3 {
    font-size: 18px;
    line-height: 25px;
  }
}









`, "",{"version":3,"sources":["webpack://./src/components/article/article.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;AACA;EACE,WAAW;EACX,YAAY;EACZ,+BAA+B;AACjC;AACA;EACE,WAAW;EACX,YAAY;;AAEd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,oBAAoB;EACpB,YAAY;AACd;AACA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;;;AAIA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".gpt3__blog-container_article {\n  width: 100%;\n  height: 100%;\n  \n  display: flex;\n  flex-direction: column;\n  background: var(--color-footer);\n}\n.gpt3__blog-container_article-image {\n  width: 100%;\n  height: 100%;\n  background: var(--color-footer);\n}\n.gpt3__blog-container_article-image img{\n  width: 100%;\n  height: 100%;\n  \n}\n.gpt3__blog-container_article-content{\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 1rem 1.5rem;\n  height: 100%;\n}\n.gpt3__blog-container_article-content h3 {\n  font-family: var(--font-family);\n  font-weight: 800;\n  font-size: 25px;\n  line-height: 30px;\n  color: #fff ;\n  margin-bottom: 5rem;\n  cursor: pointer;\n}\n.gpt3__blog-container_article-content p {\n  font-family: var(--font-family);\n  font-weight: bold;\n  font-size: 11px;\n  line-height: 35px;\n  color: #fff ;\n}\n\n.gpt3__blog-container_article-content p:last-child {\n  cursor: pointer;\n}\n\n\n\n@media screen and (max-width: 550px) {\n  .gpt3__blog-container_article-content h3 {\n    font-size: 18px;\n    line-height: 25px;\n  }\n}\n\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
