// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gpt3__possibility {
  display: flex;
  flex-direction: row;
}
.gpt3__possibility-image {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  margin-right: 2rem;
}
.gpt3__possibility-image img {
  width: 100%;
  height: 100%;
}
.gpt3__possibility-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.gpt3__possibility-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71E5ff;
}
.gpt3__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}
.gpt3__possibility-content p {
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 30px;
  color: var(--color-text);
  margin-bottom: 2rem; 
}
.gpt3__possibility-content h4:last-child {
  color: #ff8a71;
}

@media screen and (max-width: 950px){
  .gpt3__possibility {
    flex-direction: column;
  }
  .gpt3__possibility-image {
    margin: 1rem 0;
  }
  .gpt3__possibility-content {
    margin-top: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/possiblity/possiblity.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;;EAEnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,+BAA+B;EAC/B,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,cAAc;EAChB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".gpt3__possibility {\n  display: flex;\n  flex-direction: row;\n}\n.gpt3__possibility-image {\n  flex: 1;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  \n  margin-right: 2rem;\n}\n.gpt3__possibility-image img {\n  width: 100%;\n  height: 100%;\n}\n.gpt3__possibility-content {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n.gpt3__possibility-content h4 {\n  font-family: var(--font-family);\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 30px;\n  color: #71E5ff;\n}\n.gpt3__possibility-content h1 {\n  font-family: var(--font-family);\n  font-weight: 800;\n  font-size: 34px;\n  line-height: 45px;\n  margin: 1rem 0;\n}\n.gpt3__possibility-content p {\n  font-family: var(--font-family);\n  font-size: 16px;\n  line-height: 30px;\n  color: var(--color-text);\n  margin-bottom: 2rem; \n}\n.gpt3__possibility-content h4:last-child {\n  color: #ff8a71;\n}\n\n@media screen and (max-width: 950px){\n  .gpt3__possibility {\n    flex-direction: column;\n  }\n  .gpt3__possibility-image {\n    margin: 1rem 0;\n  }\n  .gpt3__possibility-content {\n    margin-top: 2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
