// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gpt3__features-container__feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  margin: 1rem;
}

.gpt3__features-container__feature-title {
  flex: 1 1;
  max-width: 180px;
  margin-right: 2rem;
}

.gpt3__features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #fff;
}

.gpt3__features-container__feature-title div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
  flex: 2 1;
  max-width: 390px;
  display: flex;
}

.gpt3__features-container_feature-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text);
}

@media screen and (max-width: 550px) {
  .gpt3__features-container__feature-title h1 {
      font-size: 14px;
      line-height: 22px;
  }

  .gpt3__features-container_feature-text p {
      font-size: 12px;
      line-height: 20px;
  }

  .gpt3__features-container__feature {
      margin: 1rem 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/feature/feature.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,mBAAmB;;EAEnB,YAAY;AACd;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,WAAW;EACX,+BAA+B;EAC/B,2CAA2C;EAC3C,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE;MACI,eAAe;MACf,iBAAiB;EACrB;;EAEA;MACI,eAAe;MACf,iBAAiB;EACrB;;EAEA;MACI,cAAc;EAClB;AACF","sourcesContent":[".gpt3__features-container__feature {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: row;\n\n  margin: 1rem;\n}\n\n.gpt3__features-container__feature-title {\n  flex: 1;\n  max-width: 180px;\n  margin-right: 2rem;\n}\n\n.gpt3__features-container__feature-title h1 {\n  font-family: var(--font-family);\n  font-weight: 800;\n  font-size: 18px;\n  line-height: 24px;\n  letter-spacing: -0.04em;\n  color: #fff;\n}\n\n.gpt3__features-container__feature-title div {\n  width: 38px;\n  height: 3px;\n  background: var(--gradient-bar);\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  margin-bottom: 0.25rem;\n}\n\n.gpt3__features-container_feature-text {\n  flex: 2;\n  max-width: 390px;\n  display: flex;\n}\n\n.gpt3__features-container_feature-text p {\n  font-family: var(--font-family);\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--color-text);\n}\n\n@media screen and (max-width: 550px) {\n  .gpt3__features-container__feature-title h1 {\n      font-size: 14px;\n      line-height: 22px;\n  }\n\n  .gpt3__features-container_feature-text p {\n      font-size: 12px;\n      line-height: 20px;\n  }\n\n  .gpt3__features-container__feature {\n      margin: 1rem 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
