// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gpt3__brand {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.gpt3__brand div {
  flex: 1 1;
  max-width: 150px;
  min-width: 120px;
  margin: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/brand/brend.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;AACrB;;;AAGA;EACE,SAAO;EACP,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;;EAEZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".gpt3__brand {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n}\n\n\n.gpt3__brand div {\n  flex: 1;\n  max-width: 150px;\n  min-width: 120px;\n  margin: 1rem;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
